
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        







































.wysiwyg-outer {
  padding-top: $spacing * 4;
}

.wysiwyg-inner {
  padding-top: $spacing * 4;
  padding-bottom: $spacing * 4;

  @include mq(m) {
    display: flex;
  }
}

.wysiwyg__title-outer {
  @include mq(m) {
    flex-shrink: 0;
    width: col(5);
  }
}

.wysiwyg__title {
  @extend %fw-medium;

  margin-top: $spacing;
  overflow-wrap: break-word;
}

.wysiwyg__content {
  @include mq(m) {
    width: col(7);
  }
}

.did-you-know__links-outer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: $spacing;
}

.did-you-know__links {
  margin-top: $spacing;
  font-size: 1.5rem;
}

.wysiwyg-content {
  padding-top: $spacing * 2;

  & > div > *:first-child {
    margin-top: 0;
  }
}
